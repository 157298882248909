/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

mat-form-field {
    margin-bottom: 5px;
}

@media print {
    body {
        background-color: white;
    }
}

@media screen {
    body {
        background: #1e3c72;
        /* fallback for old browsers */
        background: -webkit-linear-gradient( to right, #2a5298, #1e3c72);
        /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient( to right, #2a5298, #1e3c72);
        /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }
}

.mat-mdc-option .mdc-list-item__primary-text {
    margin-right: 0 !important;
    width: 100% !important;
}


/*--------------
      Loading
  ---------------*/

.loading {
    position: relative;
    cursor: default;
    pointer-events: none;
    text-shadow: none !important;
    -webkit-transition: all 0s linear;
    transition: all 0s linear;
}

.loading:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8);
    width: 100%;
    height: 100%;
    border-radius: 0.28571429rem;
    z-index: 100;
}

.loading:after {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    margin: -1.5em 0 0 -1.5em;
    width: 3em;
    height: 3em;
    -webkit-animation: loader 0.6s infinite linear;
    animation: loader 0.6s infinite linear;
    border: 0.2em solid #767676;
    border-radius: 500rem;
    -webkit-box-shadow: 0 0 0 1px transparent;
    box-shadow: 0 0 0 1px transparent;
    visibility: visible;
    z-index: 101;
}

.btn.icon-only {
    margin: 0px;
    padding: 0px;
}

.list-group-item {
    cursor: pointer;
}

.mat-mdc-option-active{
    background-color: #cc8effc4 !important;
    color: #ffffff !important;
    /* border-radius: 5px !important; */
}